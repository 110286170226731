// Footer.js
// This component renders the footer with social media links and copyright information.

import React from "react";
import ReactGA from "react-ga";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faFacebook, faInstagram, faLinkedin, faTwitter} from "@fortawesome/free-brands-svg-icons";
import "./Footer.scss";

const Footer = () => (
  <footer className="footer">
    <div className="center_text">
      {/* Copyright notice dynamically updating with the current year */}
      <div className="copyright_container">
      <em className="copyright m-0 text-center">
        &copy;{` ${new Date().getFullYear()} `}Dad's Robotics Inc
      </em>
      </div>
      <div className="footer_icon_container"> {/* Social media links with outbound link tracking */}
          {/* Facebook icon and link */}
          <ReactGA.OutboundLink
            eventLabel="Facebook"
            to="https://www.facebook.com/DadsRobotics"
            target="_blank"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" classname="FontIcon" />
          </ReactGA.OutboundLink>
          {/* Instagram icon and link */}
          <ReactGA.OutboundLink
            eventLabel="Instagram"
            to="https://www.instagram.com/dadsrobotics/"
            target="_blank"
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" classname="FontIcon" />
          </ReactGA.OutboundLink>
          {/* Twitter icon and link */}
          <ReactGA.OutboundLink
            eventLabel="Twitter"
            to="https://twitter.com/DadsRobotics"
            target="_blank"
          >
            <FontAwesomeIcon icon={faTwitter} size="2x" classname="FontIcon" />
          </ReactGA.OutboundLink>
          {/* LinkedIn icon and link */}
          <ReactGA.OutboundLink
            eventLabel="LinkedIn"
            to="https://www.linkedin.com/company/dadsrobotics/"
            target="_blank"
          >
            <FontAwesomeIcon icon={faLinkedin} size="2x" classname="FontIcon" />
          </ReactGA.OutboundLink>
          {/* Discord icon and link */}
          <ReactGA.OutboundLink
            eventLabel="Discord"
            to="https://discord.gg/dadsrobotics"
            target="_blank"
          >
            <FontAwesomeIcon icon={faDiscord} size="2x" classname="FontIcon" />
          </ReactGA.OutboundLink>
      </div>
    </div>
  </footer>
);

export default Footer;