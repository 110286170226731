import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import './Invest.scss';
import RoboDog_play_ball_FULL from '../../assets/img/invest/ROBODOG_V1_cute_ball_look_up@1.0x.png';
import RoboDog_play_ball_HALF from '../../assets/img/invest/ROBODOG_V1_cute_ball_look_up@0.5x.png';
import RoboDog_play_ball_QUARTER from '../../assets/img/invest/ROBODOG_V1_cute_ball_look_up@0.25x.png';
import boneImage from '../../assets/img/invest/bone.png';
import ROBODOG_V1_floor_model_FULL from '../../assets/img/invest/ROBODOG_V1_floor_model@1.0x.png';
import ROBODOG_V1_floor_model_HALF from '../../assets/img/invest/ROBODOG_V1_floor_model@0.5x.png';
import ROBODOG_V1_floor_model_QUARTER from '../../assets/img/invest/ROBODOG_V1_floor_model@0.25x.png';
import RoboDogOwners_FULL from '../../assets/img/invest/ROBODOG_V1_dog_and_workers@1.0x.png';
import RoboDogOwners_HALF from '../../assets/img/invest/ROBODOG_V1_dog_and_workers@0.5x.png';
import RoboDogOwners_QUARTER from '../../assets/img/invest/ROBODOG_V1_dog_and_workers@0.25x.png';
import rescueDog from '../../assets/img/invest/rescue_dog.png';
import germanShepard from '../../assets/img/invest/german_shepard.png';
import RoboDogstepup_FULL from '../../assets/img/invest/ROBODOG_V1_step_up_sq@1.0x.png';
import RoboDogstepup_HALF from '../../assets/img/invest/ROBODOG_V1_step_up_sq@0.5x.png';
import RoboDogstepup_QUARTER from '../../assets/img/invest/ROBODOG_V1_step_up_sq@0.25x.png';
import pawImage from '../../assets/img/paw.png';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga4";

function Invest() {

    useEffect(() => {
        // Track page view on component mount
        ReactGA.send({ hitType: "pageview", page: "/invest" });
    }, []);

    // Track button click event
    const trackButtonClick = () => {
        ReactGA.event({
            category: "Button",
            action: "Click",
            label: "Get Started Investment"
        });
    };

    return (
        <div>
            <Helmet>
                <title>Invest in the Future of AI - Dad's Robotics</title>
                <meta name="description" content="Invest in the future of AI and robotics with Dad's Robotics. Learn about our mission, who we are, and how you can be a part of the future." />
            </Helmet>
            <div className="first_wrapper">
                <div className="responsive-title">
                    <h1 className="page_title">Invest in the Future of AI</h1>
                </div>
                <div className="responsive-image">
                    <img
                        srcSet={`${RoboDog_play_ball_FULL} 2160w, ${RoboDog_play_ball_HALF} 1080w, ${RoboDog_play_ball_QUARTER} 540w`}
                        sizes="(min-width: 3800px) 2160px, (max-width: 768px) 540px, 1080px"
                        src={RoboDog_play_ball_HALF} // Fallback for older browsers
                        alt="RoboDog_1"
                        className="Dads_title_page_img"
                        loading="lazy"
                    />
                </div>
            </div>
            <div className="wrapper">
                <div className="responsive-title">
                    <h1 className="section_title">Our Mission</h1>
                </div>
            </div>
            <div className="wrapper">
                <div className="big_statement_container">
                    <h2>Dad's Robotics is building the future of robotics, one friend at a time.
                        <br/>
                        RoboDog serves as a loyal companion, a vigilant guardian, and a versatile assistant.
                    </h2>
                </div>
            </div>
            <div className="wrapper">
                <div className="responsive-title">
                    <h1 className="section_title">Who We Are</h1>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_vertical">
                    <div className="text_content_vertical_large">
                        <p>Dad's Robotics is a collective of Autonomy, Software, and Business professionals
                            dedicated to bringing RoboDog to fruition. Our focus is on creating a full-sized robotic
                            canine designed to navigate any terrain, understand any command, and detect a variety of
                            chemical and environmental conditions using advanced sensors.<br /><br />
                            With a focus on home robotics and security, we are committed to improving quality of life
                            through cutting-edge technology.<br /><br />
                            Dad's Robotics is specializing in developing mass deployable robotic solutions that serve
                            everyday markets.</p>
                    </div>
                    <div className="bone_container">
                        <img src={boneImage} alt="bone" className="bone" />
                        <img src={boneImage} alt="bone" className="bone" />
                        <img src={boneImage} alt="bone" className="bone" />
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="responsive-title">
                    <h1 className="section_title">Our Path to Market</h1>
                </div>
            </div>
            <div className="wrapper">
                <div className="responsive-title">
                    <h2 className="section_title">Retail / Financing</h2>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_horizontal">
                    <div className="responsive-image">
                        <img
                            srcSet={`${ROBODOG_V1_floor_model_FULL} 2160w, ${ROBODOG_V1_floor_model_HALF} 1080w, ${ROBODOG_V1_floor_model_QUARTER} 540w`}
                            sizes="(min-width: 3800px) 2160px, (max-width: 768px) 540px, 1080px"
                            src={ROBODOG_V1_floor_model_HALF} // Fallback for older browsers
                            alt="sitting_dog"
                            className="Dads_rectangular_img"
                            loading="lazy"
                        />
                    </div>
                    <div className="header_paired_with_text_right">
                        <h3>Own your very own RoboDog!<br/><br/></h3>
                        <p>Dad's RoboVet staff are the vanguard of robot veterinary engineering and will be standing
                            by to provide your RoboDog repairs or upgrades. Together, we will define
                            the future of the Robot Veterinary Service Industry.<br/><br/>
                            With financing options that won't cost more than a motorcycle, ATV, or RV payment,
                            everyday consumer robotics is no longer out of reach.
                            <br />RoboDog: The robot to redefine man's best friend.
                        </p>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="responsive-title">
                    <h2 className="section_title">Robot As A Service (RAAS)</h2>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_horizontal">
                    <div className="header_paired_with_text_left">
                        <h3>Custom Contracting</h3>
                        <p>Have a special single-use need? Interested in short-term or long-term contracting for a
                            project with a definitive start and end date?<br /><br />
                            Dad's Robotics will gladly establish custom contracting for businesses with specific use cases.<br /><br />
                            Dad's manages ownership, maintenance, and even on-site support. Whatever the job,
                            we can tailor your service plan to meet your needs.
                        </p>
                    </div>
                    <div className="responsive-image">
                        <img
                            srcSet={`${RoboDogOwners_FULL} 2160w, ${RoboDogOwners_HALF} 1080w, ${RoboDogOwners_QUARTER} 540w`}
                            sizes="(min-width: 3800px) 2160px, (max-width: 768px) 540px, 1080px"
                            src={RoboDogOwners_HALF} // Fallback for older browsers
                            alt="RoboDog owners"
                            className="Dads_rectangular_img"
                            loading="lazy"
                        />
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_vertical">
                    <div className="responsive-image">
                        <img src={rescueDog} alt="rescue_dog" className="helper_img"/>
                    </div>
                    <div className="header_paired_with_text">
                        <h3>Rescue Dog &#x1F914;</h3>
                        <p>RoboDog could be used for searches... but aren't there enough "search
                            and rescue bots" on the market? <br/>
                            At Dad's Robotics, we dare to dream bigger.
                        </p>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="responsive-title">
                    <h2 className="section_title">Private Security</h2>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_vertical">
                    <img src={germanShepard} alt="german_shepard" className="helper_img"/>
                    <div className="header_paired_with_text">
                        <h3>Guard Dog</h3>
                        <p>With RoboDog's advanced suite of sensors and AI, ensuring the security of your home or
                            business has never been easier.<br/>
                            RoboDog can accurately determine who does and does not belong on the premises. <br/>
                            With custom access lists and geofencing, you have the flexibility to be as precise or
                            general as necessary.<br/>
                            And of course, RoboDog wouldn't be complete without a bark-based alert system—custom
                            bark profiles are available to suit your preferences.
                        </p>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_horizontal">
                    <div className="responsive-image">
                        <img
                            srcSet={`${RoboDogstepup_FULL} 2160w, ${RoboDogstepup_HALF} 1080w, ${RoboDogstepup_QUARTER} 540w`}
                            sizes="(min-width: 3800px) 2160px, (max-width: 768px) 540px, 1080px"
                            src={RoboDogstepup_HALF} // Fallback for older browsers
                            alt="ROBODOG Steps up"
                            className="Dads_square_img"
                            loading="lazy"
                        />
                    </div>
                    <div className="big_statement_container">
                        <h3>And So Much More!</h3>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="responsive-title">
                    <h1 className="section_title">If you haven't contacted us for investment yet,<br/>Let's Get Started!
                    </h1>
                </div>
                <div className="use_case_pitch_vertical">
                    <div className="button_container">
                        <img src={pawImage} alt="paw" className="paw"/>
                        <Link to="/contact" className="learn-more-link">
                            <button className="learn-more-btn" onClick={trackButtonClick}>Click Here to Get Started</button>
                        </Link>
                        <img src={pawImage} alt="paw" className="paw"/>
                    </div>
                    <div className="text_content_vertical_large">
                        <p>Thank you for your interest in Dad's Robotics Corporation! We are thrilled to connect with
                            individuals and organizations eager to learn more about our cutting-edge endeavors in the
                            realm of robotics.<br /><br />
                            Let's navigate the future of technology together!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Invest;