import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import React, { forwardRef, Fragment, useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import "./Contact.scss";
import ReactGA from "react-ga4";

// Define the ContactSection component with `forwardRef` to get a reference to the recaptcha
export const ContactSection = forwardRef((props, ref) => {
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  // Added state variable to store the error message text
  const [errorText, setErrorText] = useState('');
  const [csrftoken, setcsrftoken] = useState('');
  const recaptchaRef = ref || React.createRef();

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    message: ''
  });

    // State variables to store error messages for each input
  const [errors, setErrors] = useState({
    firstname: '',
    lastname: '',
    email: '',
    message: ''
  });

  const handleRecaptcha = value => {
    setRecaptchaValue(value);
  }

  useEffect(() => {
    // Fetch the CSRF token when the component mounts
    axios.get('/api/csrf_token/', { withCredentials: true })
      .then(response => {
        const token = response.data.csrftoken;
        setcsrftoken(token);
      })
      .catch(error => {
        console.error('Error fetching CSRF token:', error);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const newErrors = {
      firstname: formData.firstname ? '' : 'First name is required.',
      lastname: formData.lastname ? '' : 'Last name is required.',
      email: formData.email ? '' : 'Email is required.',
      message: formData.message ? '' : 'Message is required.'
    };

    if (Object.values(newErrors).some(error => error !== '')) {
      setErrors(newErrors);
      return;
    }

    if (!recaptchaValue) {
      setErrorText('Please verify the reCaptcha to submit the form!');
      setShowError(true);
      return;
    }

    const newFormData = {
      'g-recaptcha-response': recaptchaValue,
      firstName: formData.firstname,
      lastName: formData.lastname,
      email: formData.email,
      message: formData.message,
    };

    axios.post('/api/submit_form/', newFormData, {
      headers: {
        'X-CSRFToken': csrftoken
      },
      withCredentials: true,
    })
    .then(response => {
      setFormData({
        firstname: '',
        lastname: '',
        email: '',
        message: ''
      });
      setRecaptchaValue(null);
      recaptchaRef.current.reset();
      setShowSuccess(true);
      setShowError(false);
      // Track form submission success event
      ReactGA.event({
        category: "Contact Form",
        action: "Submit",
        label: "Success"
      });
    })
    .catch(error => {
      console.log(error);
      setShowError(true);
      setShowSuccess(false);
      setErrorText('An error has occurred. Please try again.');
      // Track form submission error event
      ReactGA.event({
        category: "Contact Form",
        action: "Submit",
        label: "Error"
      });
    });
  }

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  }

  return (
    <div className="contact-form-container">
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="firstname">First Name</Label>
          <Input
            type="text"
            name="firstname"
            id="firstname"
            placeholder="Enter your first name"
            value={formData.firstname}
            onChange={handleInputChange}
          />
          {errors.firstname && <div className="error-message">{errors.firstname}</div>}
        </FormGroup>

        <FormGroup>
          <Label for="lastname">Last Name</Label>
          <Input
            type="text"
            name="lastname"
            id="lastname"
            placeholder="Enter your last name"
            value={formData.lastname}
            onChange={handleInputChange}
          />
          {errors.lastname && <div className="error-message">{errors.lastname}</div>}
        </FormGroup>

        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleInputChange}
          />
          {errors.email && <div className="error-message">{errors.email}</div>}
        </FormGroup>

        <FormGroup>
          <Label for="message">Message</Label>
          <Input
            type="textarea"
            name="message"
            id="message"
            placeholder="Write your message here"
            value={formData.message}
            onChange={handleInputChange}
          />
          {errors.message && <div className="error-message">{errors.message}</div>}
        </FormGroup>

        <FormGroup className="recaptcha_container">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LdV0DMpAAAAACW8osH1EfmVmniFCWGT4VsmwVEa"
            onChange={handleRecaptcha}
          />
        </FormGroup>

        <Button color="primary" type="submit">Send Message</Button>
      </Form>
      {showSuccess && <h3 className="form_message">Form submitted successfully! We will be in touch soon!</h3>}
      {showError && <h3 className="form_message">{errorText}</h3>}
    </div>
  );
});

// Main Contact Page Component
const Contact = () => {
  const recaptchaRef = React.useRef();

  useEffect(() => {
    // Track page view on component mount
    ReactGA.send({ hitType: "pageview", page: "/contact" });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Contact Us - Dad's Robotics</title>
        <meta property="og:title" content="Contact Us - Dad's Robotics" />
        <meta name="twitter:title" content="Contact Us - Dad's Robotics" />

        <link rel="canonical" href="https://dadsrobotics.com/contact" />
        <meta property="og:url" content="https://dadsrobotics.com/contact" />

        <meta name="description" content="Get in touch with Dad's Robotics. Drop us a message and one of our staff members will be in touch." />
        <meta property="og:description" content="Get in touch with Dad's Robotics. Drop us a message and one of our staff members will be in touch." />
        <meta name="twitter:description" content="Get in touch with Dad's Robotics. Drop us a message and one of our staff members will be in touch." />

        <meta name="keywords" content="Contact, Dad's Robotics, Robotics, Technology" />

        <meta name="twitter:card" content="summary" />
        <meta property="og:type" content="website" />

        <meta name="robots" content="index,follow" />
      </Helmet>
      <div className="Page_Title text-center">
        <FontAwesomeIcon icon={faEnvelopeOpenText} className="FontIcon" />
        <h1>Contact Us</h1>
      </div>
      <article id="contact" className="contact-form-wrapper">
        <div className="text-container">
          <h3>
            Are you tickled by technology? Excited by the era of home robotics? Look no further!
          </h3>
          <p>
            Let's navigate the future of technology together.
            Drop us a message and one of our staff members will be in touch.
            We can't wait to hear from you.<br /><br />

            Just a little heads-up: By hitting 'send,' you're also signing onto the Dad's Robotics
            newsletter.
            Fear not, it's your ticket to all the latest advancements and company happenings, wrapped up
            with a virtual bow. At any time, you can unsubscribe from our mailing list at the speed of light, no
            questions asked!
          </p>
        </div>
        <div className="form-container">
          <ContactSection ref={recaptchaRef} />
        </div>
      </article>
    </Fragment>
  );
}

export default Contact;