import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import './Technology.scss';
import brainboxImg_FULL from '../../assets/img/technology/BrainBox v171 _sq_wireframe@1.0x.png';
import brainboxImg_HALF from '../../assets/img/technology/BrainBox v171 _sq_wireframe@0.5x.png';
import brainboxImg_QUARTER from '../../assets/img/technology/BrainBox v171 _sq_wireframe@0.25x.png';
import paw from '../../assets/img/paw.png';
import vision_composition_FULL from '../../assets/img/technology/vision_composition@1.0x.png';
import vision_composition_HALF from '../../assets/img/technology/vision_composition@0.5x.png';
import vision_composition_QUARTER from '../../assets/img/technology/vision_composition@0.25x.png';
import ourViewImg from '../../assets/img/technology/our_view_img.png';
import roboEarMov from '../../assets/media/robo-ear.mov';
import smellSensorImg_FULL from '../../assets/img/technology/the_schnozz@1.0x.png';
import smellSensorImg_HALF from '../../assets/img/technology/the_schnozz@0.5x.png';
import smellSensorImg_QUARTER from '../../assets/img/technology/the_schnozz@0.25x.png';
import dreamSuiteImg_FULL from '../../assets/img/technology/dreamsuite@1.0x.png';
import dreamSuiteImg_HALF from '../../assets/img/technology/dreamsuite@0.5x.png';
import dreamSuiteImg_QUARTER from '../../assets/img/technology/dreamsuite@0.25x.png';
import DOGOsImg from '../../assets/img/technology/DOG_os.png';
import pawImage from "../../assets/img/paw.png";
import ReactGA from "react-ga4";

function Technology() {
    const [effectsEnabled, setEffectsEnabled] = useState(false);

    useEffect(() => {
        // Track page view on component mount
        ReactGA.send({ hitType: "pageview", page: "/technology" });

        const timeoutId = setTimeout(() => {
            setEffectsEnabled(true);
        }, 330);

        return () => clearTimeout(timeoutId);
    }, []);

    // Track button click event
    const trackButtonClick = (label) => {
        ReactGA.event({
            category: "Button",
            action: "Click",
            label: label
        });
    };

    // Function to open the overlay and load content
    function openOverlay(url) {
        var overlay = document.getElementById('overlay');
        var overlayContent = document.getElementById('overlay-content');
        var body = document.body;

        // Display the overlay
        overlay.style.display = 'block';
        body.style.overflowY = 'hidden';

        // Load the content using AJAX
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            if (xhr.status >= 200 && xhr.status < 300) {
                // Success! Add the content to the overlay
                overlayContent.innerHTML = xhr.responseText;
            } else {
                // Handle errors (e.g., content not found)
                overlayContent.innerHTML = '<p>Content could not be loaded.</p>';
            }
        };
        xhr.open('GET', url); // Use the URL parameter
        xhr.send();
    }

    // Function to close the overlay
    function closeOverlay() {
        var overlay = document.getElementById('overlay');
        var body = document.body;

        overlay.style.display = 'none';
        body.style.overflowY = 'auto';
    }

    // Set up event listeners for buttons that will open the overlay
    useEffect(() => {
        var overlayButtons = document.querySelectorAll('[data-overlay-target]');
        overlayButtons.forEach(function(button) {
            button.addEventListener('click', function(event) {
                var targetUrl = button.getAttribute('data-overlay-target'); // Get the URL from the data attribute
                openOverlay(targetUrl);
                trackButtonClick(button.textContent); // Track button click event
            });
        });

        // Add event listener to the overlay to close it when clicked outside the content
        var overlay = document.getElementById('overlay');
        if (overlay) {
            overlay.addEventListener('click', function(event) {
                if (event.target === overlay) {
                    closeOverlay();
                }
            });
        }

        // Add a close button or some method to close the overlay from the inside (optional)
        // This would require a close button inside the overlay-content with an id or class
        var closeButton = document.querySelector('.button_container .back-btn'); // Assuming you have a close button
        if (closeButton) {
            closeButton.addEventListener('click', function() {
                closeOverlay();
            });
        }

        window.closeOverlay = closeOverlay;
    }, []);

    return (
        <div>
            <Helmet>
                <title>Technology Behind RoboDog - Dad's Robotics</title>
                <meta name="description" content="Explore the advanced technology behind RoboDog, including BrainBox, machine vision, audio sensors, and more." />
            </Helmet>
            {/* Overlay container */}
            <div id="overlay" className="T_overlay">
                <div className="overlay-content" id="overlay-content">
                </div>
            </div>
            {/*End of Overlay container*/}

            <div className="first_wrapper">
                <div className="responsive-title">
                    <h1 className="page_title">What's In RoboDog?</h1>
                </div>
            </div>
            <div className="wrapper">
                <div className="responsive-title">
                    <h2 className="section_title">Introducing... Dad's BrainBox!</h2>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_horizontal">
                    <div className="responsive-image">
                        <img
                            srcSet={`${brainboxImg_FULL} 2160w, ${brainboxImg_HALF} 1080w, ${brainboxImg_QUARTER} 540w`}
                            sizes="(min-width: 3800px) 2160px, (max-width: 768px) 540px, 1080px"
                            src={brainboxImg_HALF} // Fallback for older browsers
                            alt="dads brainbox"
                            className="Dads_square_img"
                            loading="lazy"
                        />
                    </div>
                    <div className="header_paired_with_text_right">
                        <h3>Every dog needs a brain</h3>
                        <p>Dad's BrainBox serves as the modular hub for all sensor and computer integrations within
                            RoboDog.<br/><br/>
                            It is the foundational component for all future robotic systems.
                        </p>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="animation-button">
                    <div className="button_container">
                        <img src={paw} alt="paw" className="paw"/>
                        <button className="learn-more-btn" data-overlay-target="/overlay-content/brain_b.html">
                            Learn More About Dad's BrainBox
                        </button>
                        <img src={pawImage} alt="paw" className="paw"/>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="responsive-title">
                    <h2 className="section_title">Machine Vision is Solved</h2>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_horizontal">
                    <div className="responsive-image">
                        <img
                            srcSet={`${vision_composition_FULL} 2160w, ${vision_composition_HALF} 1080w, ${vision_composition_QUARTER} 540w`}
                            sizes="(min-width: 3800px) 2160px, (max-width: 768px) 540px, 1080px"
                            src={vision_composition_HALF} // Fallback for older browsers
                            alt="dads vision"
                            className="Dads_square_img"
                            loading="lazy"
                        />
                    </div>
                    <div className="header_paired_with_text_right">
                        <p>We use state of the art cameras for full 360° view of the world.<br/>
                            RoboDog can see in the dark as well as it can see during daylight.<br/>
                            NO bulky LIDAR units required
                        </p>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_horizontal">
                    <div className="text_content_vertical_small">
                        <p>Laser Depth-Finding + Infrared Projector
                            = Low-Light/Night Vision
                        </p>
                    </div>
                    <img src={ourViewImg} alt="our_view" className="our_v" />
                </div>
            </div>
            <div className="wrapper">
                <div className="animation-button">
                    <div className="button_container">
                        <img src={paw} alt="paw" className="paw"/>
                        <button className="learn-more-btn" data-overlay-target="/overlay-content/vision.html">
                            Learn More About Our Machine Vision
                        </button>
                        <img src={pawImage} alt="paw" className="paw"/>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="responsive-title">
                    <h2 className="section_title">Understands Real Language!</h2>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_vertical">
                    <video loop autoPlay muted playsInline src={roboEarMov} className="robo_ear_clip"></video>
                    <div className="text_content_vertical_small">
                        <p>High quality microphones ensures speech is heard then processed correctly.</p>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="animation-button">
                    <div className="button_container">
                        <img src={paw} alt="paw" className="paw"/>
                        <button className="learn-more-btn" data-overlay-target="/overlay-content/audio.html">
                            Learn More About Our Audio Sensors
                        </button>
                        <img src={pawImage} alt="paw" className="paw"/>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="responsive-title">
                    <h2 className="section_title">Smart Nose for a Smart Dog</h2>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_horizontal">
                    <div className="responsive-image">
                        <img
                            srcSet={`${smellSensorImg_FULL} 2160w, ${smellSensorImg_HALF} 1080w, ${smellSensorImg_QUARTER} 540w`}
                            sizes="(min-width: 3800px) 2160px, (max-width: 768px) 540px, 1080px"
                            src={smellSensorImg_HALF} // Fallback for older browsers
                            alt="smell sensor"
                            className="Dads_rectangular_img"
                            loading="lazy"
                        />
                    </div>
                    <div className="header_paired_with_text">
                        <p>RoboDog is able to detect any unusual gasses, odors, or smells.</p>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="big_statement_container">
                    <p>Senses air-quality, temperature, air-pressure, and much more!</p>
                </div>
            </div>
            <div className="wrapper">
                <div className="animation-button">
                    <div className="button_container">
                        <img src={paw} alt="paw" className="paw"/>
                        <button className="learn-more-btn" data-overlay-target="/overlay-content/smell.html">
                            Learn More About Our Smell Sensors
                        </button>
                        <img src={pawImage} alt="paw" className="paw"/>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="responsive-title">
                    <h2 className="section_title">DreamSuite</h2>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_horizontal">
                    <div className="responsive-image">
                        <img
                            srcSet={`${dreamSuiteImg_FULL} 2160w, ${dreamSuiteImg_HALF} 1080w, ${dreamSuiteImg_QUARTER} 540w`}
                            sizes="(min-width: 3800px) 2160px, (max-width: 768px) 540px, 1080px"
                            src={dreamSuiteImg_HALF} // Fallback for older browsers
                            alt="dream_suite"
                            className="Dads_rectangular_img"
                            loading="lazy"
                        />
                    </div>
                    <div className="text_content_vertical_large">
                        <p>Internal Simulations teach RoboDog how to move.<br/><br/>
                            Robust self-modeling allows the dog to be 100% aware of its surroundings.<br/><br/>
                            All computing work is performed when RoboDog is "asleep" and after charging is complete.
                        </p>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="animation-button">
                    <div className="button_container">
                        <img src={paw} alt="paw" className="paw"/>
                        <button className="learn-more-btn" data-overlay-target="/overlay-content/dream.html">
                            Learn More About Our DreamSuite
                        </button>
                        <img src={pawImage} alt="paw" className="paw"/>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="responsive-title">
                    <h2 className="section_title">DogOS</h2>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_horizontal">
                    <img src={DOGOsImg} alt="dog_os" className="dog_os" />
                    <div className="text_content_vertical_small">
                        <p>Seamless integration of existing ROS2 libraries / Tools + Nvidia GPU frameworks all inside a
                            custom Linux OS.
                        </p>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="button_container">
                    <img src={paw} alt="paw" className="paw"/>
                    <button className="learn-more-btn" data-overlay-target="/overlay-content/dog-os.html">
                        Learn More About DogOS
                    </button>
                    <img src={pawImage} alt="paw" className="paw"/>
                </div>
            </div>
        </div>
    );
}

export default Technology;