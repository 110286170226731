// Navigation.js
// This component provides navigation for the site.

import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import logo from '../../assets/img/navbar_logo(720x718).png';
import './Navigation.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";


export default function Navigation() {
  // State to handle the collapsed state of the navigation bar
  const [isOpen, setIsOpen] = useState(false);

  // Toggle function to open/close the navbar
  const toggle = () => setIsOpen(!isOpen);

  // Close the navigation bar when a link is clicked
  const closeMenu = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  // JSX for the navigation component
  return (
    <nav className="navbar fixed-top shadow" id="navbarResponsive">
      <div className="container">
        {/* Brand logo and text; closes menu upon clicking */}
        <NavLink className="navbar-brand text-uppercase" to="/" onClick={closeMenu}>
          <img className="logo" src={logo} alt="Logo" />
          <div className="company_font_with_gold_coloring"> Dad's Robotics</div>
        </NavLink>
        {/* Toggler button for small screens */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggle}
          aria-controls="navbarResponsive"
          aria-expanded={isOpen ? 'true' : 'false'}
          aria-label="Toggle navigation"
        >
          <FontAwesomeIcon icon={faBars} className="navbar-toggler-icon"/>
        </button>

        {/* Collapsible content containing navigation links */}
        <div className={`navbar-menu${isOpen ? ' active' : ''}`} id="navbarResponsive">
          <ul className="navbar-nav ms-auto">
            {/* Navigation item for Home */}
            <li className="nav-item">
              <NavLink className="nav-link" to="/home" onClick={closeMenu}>
                Home
              </NavLink>
            </li>
            {/* Navigation item for Invest */}
            <li className="nav-item">
              <NavLink className="nav-link" to="/invest" onClick={closeMenu}>
                Invest
              </NavLink>
            </li>
            {/* Navigation item for Technology */}
            <li className="nav-item">
              <NavLink className="nav-link" to="/technology" onClick={closeMenu}>
                Technology
              </NavLink>
            </li>
            {/* Navigation item for Contact Us */}
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact" onClick={closeMenu}>
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

