// App.js
// This is the root component of the application where routing and common layout components are configured.

import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";

// Importing individual page components
import Home from './common/Home/Home';
import Contact from './common/Contact/Contact';
import Technology from "./common/Technology/Technology";
import Invest from "./common/Invest/Invest";

// Common layout components
import Navigation from "./common/layout/Navigation";
import Footer from "./common/layout/Footer";

// Application-wide styles
import "./App.scss";
import ReactGA from "react-ga4";

ReactGA.initialize("G-0DDMKY68FQ");

// Custom component to handle the scroll to top behavior on route changes
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]); // Triggered on path changes

  return null; // This component does not render anything
}


// App component handles routing and layout structure
export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop /> {/* Include the ScrollToTop component */}
      {/* Main container for the application */}
      <div className="main-container">
        {/* Navigation bar shown at the top of all pages */}
        <Navigation className="navigation" />
        
        {/* Main content area where different pages will be rendered based on the route */}
        <div id="intro">
          <Routes>
            <Route path="/" element={<Home />} /> {/* Route for the Home page */}
            <Route path="invest" element={<Invest />} /> {/* Route for the Invest page */}
            <Route path="technology" element={<Technology />} /> {/* Route for the Technology page */}
            <Route path="contact" element={<Contact />} /> {/* Route for the Contact page */}
            <Route path="*" element={<Home />} /> {/* Fallback route to Home when no match is found */}
          </Routes>
        </div>
        {/* Footer shown at the bottom of all pages */}
        <Footer className="footer" />
      </div>
    </BrowserRouter>
  );
}