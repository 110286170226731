import React from 'react';
import {Helmet} from "react-helmet";
import './Home.scss';
import Meet_ROBODOG_V1_FULL from '../../assets/img/home/Meet_ROBODOG_V1@1.0x.png';
import Meet_ROBODOG_V1_HALF from '../../assets/img/home/Meet_ROBODOG_V1@0.5x.png';
import Meet_ROBODOG_V1_QUARTER from '../../assets/img/home/Meet_ROBODOG_V1@0.25x.png';
import robodog_chasing_ball_FULL from '../../assets/img/home/ROBODOG_V1_cute_ball@1.0x.png';
import robodog_chasing_ball_HALF from '../../assets/img/home/ROBODOG_V1_cute_ball@0.5x.png';
import robodog_chasing_ball_QUARTER from '../../assets/img/home/ROBODOG_V1_cute_ball@0.25x.png';
import paw from '../../assets/img/paw.png';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBatteryFull,
    faDollarSign,
    faShieldDog,
    faUserShield
} from "@fortawesome/free-solid-svg-icons";
import { faEnvira } from "@fortawesome/free-brands-svg-icons";
import ReactGA from "react-ga4";

function Home() {

    // Track button click event
    const trackButtonClick = () => {
        ReactGA.event({
            category: "Button",
            action: "Click",
            label: "Learn more about investing"
        });
    };

    return (
        <div>
            <Helmet>
                <title>Meet RoboDog - Dad's Robotics</title>
                <meta name="description" content="Discover RoboDog, the AI-powered solution to the pet industry's problems." />
            </Helmet>
            <div className="first_wrapper">
                <div className="responsive-title">
                    <h1 className="page_title">Meet RoboDog</h1>
                </div>
                <div className="responsive-image">
                    <img
                        srcSet={`${Meet_ROBODOG_V1_FULL} 2160w, ${Meet_ROBODOG_V1_HALF} 1080w, ${Meet_ROBODOG_V1_QUARTER} 540w`}
                        sizes="(min-width: 3800px) 2160px, (max-width: 768px) 540px, 1080px"
                        src={Meet_ROBODOG_V1_HALF} // Fallback for older browsers
                        alt="RoboDog_1"
                        className="Dads_title_page_img"
                        loading="lazy"
                    />
                </div>
            </div>
            <div className="wrapper">
                <div className="responsive-title">
                    <h2 className="section_title">The Pet Industry Has a Problem</h2>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_horizontal">
                    <div className="text_content_vertical_small">
                        <p>Owning and properly maintaining a pet comes at a hefty cost.<br></br>
                        The lifetime upkeep of each animal can require anywhere from
                        $15,000 to $90,000. </p>
                    </div>
                    <FontAwesomeIcon icon={faDollarSign} className="FontIcon" />
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_horizontal">
                    <FontAwesomeIcon icon={faShieldDog} className="FontIcon" />
                    <div className="text_content_vertical_small">
                        <p> Private security, service animals, and police animals cost even more.
                            Working-dogs often exceed $50,000 for specialized training.
                        </p>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="responsive-title">
                    <h2 className="section_title">The Solution is RoboDog</h2>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_horizontal">
                    <div className="responsive-image">
                        <img
                            srcSet={`${robodog_chasing_ball_FULL} 2160w, ${robodog_chasing_ball_HALF} 1080w, ${robodog_chasing_ball_QUARTER} 540w`}
                            sizes="(min-width: 3800px) 2160px, (max-width: 768px) 540px, 1080px"
                            src={robodog_chasing_ball_HALF} // Fallback for older browsers
                            alt="robodog chasing ball"
                            className="Dads_rectangular_img"
                            loading="lazy"
                        />
                    </div>
                    <div className="header_paired_with_text_right">
                        <h3>Powered by Artificial Intelligence (AI)</h3>
                        <p>At the core of RoboDog is a robust AI system that's
                            nothing short of a technological marvel.
                        </p>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_horizontal">
                    <div className="header_paired_with_text">
                        <h3>Your Privacy, Our Promise</h3>
                        <p>At Dad's Robotics, WE DON'T WANT YOUR DATA. Period.
                            RoboDog's brain is a fortress, and your secrets are safe within it's walls.
                        </p>
                    </div>
                    <FontAwesomeIcon icon={faUserShield} className="FontIcon" />
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_horizontal">
                    <FontAwesomeIcon icon={faEnvira} className="FontIcon" />
                    <div className="header_paired_with_text">
                        <h3>Eco-Friendly Life Cycle</h3>
                        <p>Dad's Robotics production pipeline has been
                            designed with refurbishment and re-use in mind.
                        </p>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_horizontal">
                    <div className="header_paired_with_text">
                        <h3>Energy-Efficient Technology</h3>
                        <p>With a High-Capacity Battery
                            and a design that minimizes energy use,
                            RoboDog always operates at peak performance.
                        </p>
                    </div>
                    <FontAwesomeIcon icon={faBatteryFull} className="FontIcon" />
                </div>
            </div>
            <div className="wrapper">
                <div className="responsive-title">
                    <h2 className="section_title">Invest In The Future</h2>
                </div>
            </div>
            <div className="wrapper">
                <div className="use_case_pitch_vertical">
                    <div className="header_paired_with_text">
                        <h3>At Dad's Robotics...</h3>
                        <p>We are here to provide you with a world-class RoboDog.
                            <br></br>
                            Journey with us to our invest page and explore the future of robotics.
                        </p>
                    </div>
                    <div className="button_container">
                        <img src={paw} alt="paw" className="paw" />
                        <a href="invest/" className="learn-more-link">
                            <button className="learn-more-btn" onClick={trackButtonClick}>Learn more about investing</button>
                        </a>
                        <img src={paw} alt="paw" className="paw" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;